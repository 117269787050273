﻿/// <reference path="../utils/_variables.scss" />

html {
	height: 100%;
}

body {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: Arial;
	background-color: $color-charcoal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.action {
	cursor: pointer;
}

@media (min-width: 600px) {
	.iphone-only {
		display: none;
	}
}

@media only screen 
  and (min-device-width: 568px) {
	.iphone-only {
		display: none !important;
	}
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
	.non-iphone-only {
		display: none !important;
	}
}
