﻿/// <reference path="../utils/_variables.scss" />

.view-wrapper {
	height: 100%;

	.view {
		display: flex;
		flex-direction: column;
		height: 100%;

		.view-content {
			overflow-y: auto;
			height: 100%;
			width: 100%;

			.section {
				height: 100%;
				max-width: 600px;
				margin: 0 auto;

				.section-content {
					height: 100%;
				}
			}
		}
	}
}

mm-header {
	background-color: $blue-medium;
}

nav {
	display: flex;
	justify-content: space-around;

	.nav-item {
		padding: 10px 5px;
		text-align: center;
		flex: 10;

		&:hover {
			cursor: pointer;
		}

		&.active {
			i {
				color: $blue-light;
			}
		}

		&.menu {
			flex: 1;
			text-align: right;
			padding: 10px 15px;

			&.open {
				background-color: $blue-dark;
				color: $color-white;
			}

			.dropdown-menu {
				background-color: $blue-dark;
				padding: 5px 10px;
				margin: 0;
				border-radius: 0;
				border: none;
			}
		}

		i {
			font-size: 36px;
			color: $color-white;
		}
	}
}

.sub-nav {
	background-color: $color-white;
	opacity: .85;

	.nav-item {
		overflow: hidden;

		&.active {
			i {
				color: $blue-light;
			}

			span {
				color: $blue-light;
			}
		}

		i {
			font-size: 22px;
			color: $color-charcoal;
			display: block;
		}

		span {
			font-size: 12px;
			vertical-align: top;
		}
	}
}

footer {
	background-color: $blue-medium;
	text-align: center;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 10px;
	color: $color-white;
	font-size: 14px;
	display: none;
}
