﻿/**
 * This file holds the colors used throughout the application.
*/

$color-black: #000;
$color-white: #fff;

$color-grey-light: #ccc;
$color-grey: #777;
$color-charcoal: #404040;

$color-red-error: #ff3b30;
$color-yellow-warning: #ff9500;

/* Theme colors */
$blue-light: #00ace9;
$blue-medium: #005674;
$blue-dark: #002b3a;

$green-light: #6a9a1f;
$green-medium: #354d0f;
$green-dark: #1b2708;

$red-light: #d43f3f;
$red-medium: #6a1f1f;
$red-dark: #351010;

$yellow-light: #e2ac01;
$yellow-medium: #715600;
$yellow-dark: #392b00;
