﻿/// <reference path="../utils/_variables.scss" />

.date-nav {
	display: flex;
	background-color: $color-white;
	font-size: 22px;
	align-items: center;

	div {
		padding: 5px 15px;
		border-bottom: 1px solid $color-charcoal;

		&.date-nav-select {
			flex: 10;
			border-left: 1px solid $color-charcoal;
			border-right: 1px solid $color-charcoal;
			text-align: center;

			input {
				border: none;
				font-size: 18px;
				padding: 0;
				margin: 0;
				text-align: center;
				-webkit-appearance: none;
			}
		}
	}
}
