﻿/// <reference path="./utils/_variables.scss" />

header {
	font-weight: bold;
	padding: 7px 5px;
	background-color: $red-light;
	color: $color-white;
	border-bottom: 1px solid $red-dark;

	.header {
		display: flex;
		justify-content: space-between;
	}

	.header-content {
		flex: 5;
		text-align: center;
	}

	.action {
		width: 30px;
		text-align: center;

		i {
			font-size: 18px;
			vertical-align: middle;
		}
	}

	input[type=text] {
		width: 100%;
		color: $color-black;
	}
}

.view-content {
}

.content-section {
	background-color: $color-white;
	display: flex;
	flex-direction: column;
	height: 100%;

	.content {
		padding: 10px;
		overflow-y: auto;

		&.form,
		&.log {
			padding: 0;
		}
	}
}

.dashboard-section {
	padding-bottom: 20px;

	&:last-of-type {
		padding-bottom: 0;
	}
}

.log,
.form {
	background-color: $color-white;

	.entry {
		color: $color-charcoal;

		.entry-col {
			padding: 10px;

			&.entry-col-right {
				text-align: right;
			}

			&.entry-col-action {
				padding: 0;
				flex: 1;

				.delete-action {
					background-color: $color-red-error;
					color: $color-white;
					padding: 10px;
				}

				.edit-action {
					background-color: $color-yellow-warning;
					padding: 10px;
				}

				i {
					font-size: 24px;
					padding: 0 10px;
					color: $color-grey;
				}
			}

			input {
				width: 160px;
			}
		}
	}
}

.subtext {
	font-size: 12px;
	padding-top: 3px;
	color: $color-grey;
}

.log {
	.entry {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid $color-grey-light;
		border-top: none;
		padding: 0 3px;

		.entry-col {
			padding: 5px;
		}
	}
}

.form {
	.entry {
		padding: 5px;

		&.entry-split {
			display: flex;
			justify-content: space-between;
			align-items: center;

			label {
				margin: 0;
			}

			.entry-col {
				padding: 0 0 0 20px;

				&:first-of-type {
					padding: 0;
				}
			}
		}
	}

	md-input-container {
		width: 100%;
		margin: 15px 0 5px 0;

		label {
			margin-bottom: 0;
		}

		.md-errors-spacer {
			display: none;
		}
	}

	md-autocomplete[md-floating-label] {
		.md-show-clear-button {
			button {
				top: 17px;
			}
		}
	}
}

.slide-out {
	.modal-dialog {
		margin: 0 auto;
		height: 100%;
		max-height: 600px;
	}

	.modal-content {
		border: none;
		border-radius: 0;
		height: 100%;
	}
}

.md-autocomplete-suggestions-container {
	margin-top: 10px;
	margin-left: -1px;
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container,
.md-select-menu-container {
	z-index: 1091;
}

md-autocomplete md-autocomplete-wrap md-progress-linear.md-inline {
	bottom: 3px;
}

.md-scroll-mask {
	z-index: 1051;
}

md-backdrop.md-select-backdrop {
	z-index: 1082;
}

.nutrition-facts-wrapper {
	border: 1px solid $color-black;
	padding: 10px 10px 8px 10px;

	.nutrition-facts-header {
		font-weight: bold;
		font-size: 35px;
		font-family: 'Helvetica';
		line-height: 36px;
		margin-bottom: -5px;
	}

	.serving-info {
	}

	.nutrition-facts {
		border-top: 13px solid $color-black;

		&.thin {
			border-width: 5px;
		}

		.nutrition-fact {
			border-top: 1px solid #000;
			margin-left: 20px;
			display: flex;

			&:first-of-type {
				border-top: none;
			}

			&.main {
				margin-left: 0;

				label {
					font-weight: bold;
				}
			}

			&.calories {
				font-weight: bold;
				font-family: "Helvetica";

				label {
					font-size: 20px;
					line-height: 20px;
					padding-top: 12px;
				}

				.value {
					flex: 10;
					text-align: right;
					font-size: 26px;
					line-height: 26px;
					padding-top: 6px;
				}
			}

			label {
				font-weight: normal;
				margin: 0;
			}

			.value {
				padding: 0 5px;
			}

			.percent-dv {
				font-weight: bold;
				flex: 10;
				text-align: right;
			}
		}
	}
}
