﻿/// <reference path="../utils/_variables.scss" />

.view-inventory {
	mm-header {
		background-color: $yellow-medium;

		nav {
			.active {
				i {
					color: $yellow-light;
				}
			}
					

			.menu {
				&.open {
					background-color: $yellow-dark;
				}

				.dropdown-menu {
					background-color: $yellow-dark;
				}
			}
		}
	}

	footer {
		background-color: $yellow-medium;
	}

	.sub-nav {
		.active {
			i {
				color: $yellow-light;
			}

			span {
				color: $yellow-light;
			}
		}
	}
}
