﻿/// <reference path="../utils/_variables.scss" />

@font-face {
	font-family: 'Helvetica';
	src: url('../../dist/fonts/HelveticaNeueLTStd-Blk.otf');
}

body {
	font-family: Arial;
}

h1, h2, h3, h4 {
	margin: 0;
}

input {
	font-family: Arial;
}
