﻿/// <reference path="../utils/_variables.scss" />

.view-health {
	mm-header {
		background-color: $red-medium;

		nav {
			.active {
				i {
					color: $red-light;
				}
			}

			.menu {
				&.open {
					background-color: $red-dark;
				}

				.dropdown-menu {
					background-color: $red-dark;
				}
			}
		}
	}

	footer {
		background-color: $red-medium;
	}

	.sub-nav {
		.active {
			i {
				color: $red-light;
			}

			span {
				color: $red-light;
			}
		}
	}

	.food-log {
		display: flex;
		flex-direction: column;
		height: 100%;
		color: $color-charcoal;

		.food-summary {
			display: flex;
			flex-direction: row;
			width: 100%;
			background-color: $color-white;

			div {
				flex-grow: 1;
				text-align: center;

				.food-summary-value {
					padding-top: 5px;
				}

				.food-summary-label {
					font-size: 12px;
					color: $color-grey;
					padding-bottom: 5px;
				}
			}
		}

		.food-log-actions {
			font-size: 14px;
			font-weight: bold;
			border-top: 1px solid $color-charcoal;
			border-bottom: 1px solid $color-charcoal;
			background-color: $red-light;
			color: $color-white;

			span {
				padding: 5px;
				display: inline-block;
			}
		}

		.food-list-wrapper {
			overflow-y: auto;

			.food-list {
				display: table;
				width: 100%;
				background-color: $color-white;

				.food-item {
					display: table-row;

					.food-info,
					.nutrition-info {
						display: table-cell;
						padding: 5px 10px;
						vertical-align: middle;
						border-bottom: 1px solid $color-grey-light;
						font-size: 14px;
					}

					.food-info {
						width: 99%;

						.food-serving {
							font-size: 12px;
							padding-top: 3px;
							color: $color-grey;
						}
					}

					.nutrition-info {
					}
				}
			}
		}
	}
}
