﻿/// <reference path="../utils/_variables.scss" />

.food-log-summary {
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: $color-white;
	padding: 5px 0;

	div {
		flex-grow: 1;
		text-align: center;
	}
}
